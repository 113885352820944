<template>
	<div class="e">
		<el-tabs v-model="activeNav">
			<el-tab-pane
				:label="item.title"
				v-for="(item,index) in navs"
				:key="index"
				:name="item.type"></el-tab-pane>
		</el-tabs>
		<div class="e-content">
			<component
				:eid="$route.query.id"
				:is="comName"
				:info="info"></component>
		</div>
		<div style="text-align:center">
			<el-button style="width:100px;" type="primary" @click="()=>{ this.$router.go(-1)}">返回</el-button>
		</div>
	</div>
</template>

<script>
	import { mapActions } from 'vuex'
	import enterpriseInfo from '../components/enterpriseInfo.vue'  //企业信息
	import customerList from '../components/customerList.vue'  //客户列表
	export default{
		data(){
			return{
				navs:[
					{title:'企业信息',type:'0',name:'enterpriseInfo'},
					{title:'客户列表',type:'1',name:'customerList'},
				],
				activeNav:'0',
				info:{},
			}
		},
		components:{
			enterpriseInfo,
			customerList
		},
		computed:{
			comName(){
				return this.navs.filter(v => v.type === this.activeNav)[0].name;
			}
		},
		methods:{
			...mapActions('supplierModule',[
				'myEnterpriseInfo',  //获取企业详情
			]),
			getDetails(){ //获取企业详情信息
				this.myEnterpriseInfo(this.$route.query.id).then(res => {
					let {code,data} = res.data;
					if(code === '0'){
						this.info = data;
					}
				})
			}
		},
		created(){
			this.getDetails();
		}
	}
</script>

<style scoped lang="less">
	.e{
		height: 100%;
		display: flex;
		flex-direction: column;
		.e-content{
			flex: 1;
		}
	}
</style>
